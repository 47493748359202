<template>
  <div class="end-container" id="end">
    <div class="end-banner">
      <div class="banner-title">Последние годы<span>(07)</span></div>
      <div class="banner-img">
        <img src="@/assets/img/end.png" alt="End">
      </div>
    </div>
    <div class="medium-container">
      <div class="main-container__year">
        <p>
          5 октября 2011 г.
        </p>
      </div>
      <div class="main-container__text">
        В 2003 году у Стива Джобса был диагностирован рак поджелудочной железы, но он объявил о своей болезни общественности только в 2004 году.
        В 2009 году ему была сделана операция по пересадке печени, а в период борьбы с болезнью он взял несколько отпусков в компании Apple.
        <br> <br>
        Стив Джобс ушел с поста генерального директора Apple в августе 2011 года, объяснив это состоянием своего здоровья. Он скончался 5 октября 2011 года в возрасте 56 лет.

      </div>
    </div>
    <div class="lower-container" id="inheritance">
      <div class="lower-title">Наследие</div>
      <div class="lower-text m-top-50">
        Стива Джобса помнят как провидца и гения, который изменил технологическую отрасль своими инновационными продуктами и разработками.
        Он был сложной и противоречивой фигурой, но его влияние на мир технологий неоспоримо.
        <br> <br>
        В своей биографии Стива Джобса автор Уолтер Айзексон пишет: "Стив Джобс является иконой изобретательности и прикладного воображения.
        Он знал, что лучший способ создать ценность в 21 веке - это соединить творчество с технологией, поэтому он построил компанию, в которой скачки воображения сочетались с выдающимися инженерными подвигами".
      </div>
      <div class="lower-title m-top-50">Заключение</div>
      <div class="lower-text m-top-50">
        Жизнь Стива Джобса стала свидетельством силы инноваций и творчества.
        Он был настоящим визионером, оказавшим глубокое влияние на технологическую индустрию и на мир в целом.
        Его история - это история триумфов, неудач и извлеченных уроков, и она служит вдохновением для предпринимателей и творческих личностей во всем мире.
      </div>
    </div>
  </div>
</template>

<script>
import revealAnimate from "@/helpers/reveal.animation";

export default {
  name: "EndOfYear",

  mounted() {
    revealAnimate('.end-banner', '20%')
  }
}
</script>

<style scoped>

.m-top-50 {
  margin-top: 50px;
}

.end-container {
  margin-top: 120px;
}

.end-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-title {
  font-family: "SF UI Display Semibold";
  font-size: 70px;
  text-align: center;
  color: white;
  position: relative;
  top: 100px;
  left: 350px;
}

.banner-title span {
  font-size: 1rem;
  font-style: italic;
}

.banner-img {
  width: 100%;
}

.banner-img img {
  width: 100%;
}

.medium-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.main-container__year {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container__year p {
  font-family: "SF UI Display Semibold";
  font-size: 3rem;
  font-style: italic;
}

.main-container__text {
  width: 65%;
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
}

.lower-container {
  margin-top: 100px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.lower-title {
  font-family: "SF UI Display Semibold";
  font-size: 3rem;
  text-align: center;
}

.lower-text {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
  text-align: left;
}

@media only screen and (max-width: 1200px) {
  .banner-title {
    left: 200px;
  }

  .main-container__year {
    width: 38%;
  }

  .main-container__text {
    width: 60%;
  }
}

@media only screen and (max-width: 1024px) {
  .banner-title {
    left: 70px;
  }
}

@media only screen and (max-width: 812px) {
  .end-container {
    margin-top: 50px;
  }

  .banner-title {
    left: 100px;
    font-size: 3rem;
  }
}

@media only screen and (max-width: 575px) {
  .end-container {
    margin-top: 1rem;
  }

  .banner-title {
    left: 50px;
    font-size: 2rem;
    top: 50px;
  }

  .medium-container {
    margin-top: 2rem;
    flex-direction: column;
  }

  .main-container__year {
    width: 100%;
  }

  .main-container__text {
    width: 100%;
  }

  .main-container__year p {
    font-size: 1.25rem;
    margin-bottom: 1rem
  }

  .lower-container {
    margin-top: 50px;
    width: 100%;
  }

  .lower-title {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 455px) {
  .banner-title {
    left: 0;
  }

  .medium-container {
    margin-top: 1rem;
  }

  .lower-text {
    margin-top: 2rem;
  }
}

</style>