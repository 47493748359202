<template>
  <div class="app-container">
    <div>
      <Header @window-resized="changeChar"/>
      <ContextList/>
      <Childhood/>
      <Education/>
      <AppleCompany/>
      <ReturnToApple/>
      <i-pod-ai-tunes/>
      <i-phone-ai-pad/>
      <end-of-year/>
      <Footer/>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import Header from "@/components/Header";
import ContextList from "@/components/ContextList";
import Childhood from "@/components/Childhood";
import Education from "@/components/Education";
import AppleCompany from "@/components/AppleCompany";
import ReturnToApple from "@/components/ReturnToApple";
import IPodAiTunes from "@/components/iPodAiTunes";
import IPhoneAiPad from "@/components/iPhoneAiPad";
import EndOfYear from "@/components/EndOfYear";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    EndOfYear,
    IPhoneAiPad, IPodAiTunes, ReturnToApple, AppleCompany, Education, Childhood, ContextList, Header
  },

  setup() {
    let charTransform = ref(window.innerWidth / 6.8 + 'px');
    let loadingPage = ref(true);

    function changeChar(value) {
      charTransform.value = value
    }

    return {
      changeChar,
      charTransform,
      loadingPage,
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'SF UI Display Medium';
  src: url("@/assets/fonts/SFUIDisplay-Medium.ttf");
}

@font-face {
  font-family: 'SF UI Display Regular';
  src: url("@/assets/fonts/SFUIDisplay-Regular.ttf");
}

@font-face {
  font-family: 'SF UI Display Semibold';
  src: url("@/assets/fonts/SFUIDisplay-Semibold.ttf");
}

html, body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: sans-serif, -apple-system, Arial, Helvetica, "Segoe UI", Roboto
}

.app-container {
  width: 95%;
  margin: 0 auto;
}

.char {
  transform: translateY(v-bind(charTransform));
  transition: transform .5s;
}

</style>
