<template>
  <div class="footer-container">
    <div class="line"></div>
    <div class="footer-info">
      <div class="logo">
        <a href="https://www.longreed.uz" target="_blank">
          <span>
            <img src="@/assets/img/logo.svg" alt="Logo">
          </span>
        </a>
      </div>
      <div class="footer-text">некоммерческий проект</div>
      <div class="footer-text">Разработано командой Longreed</div>
      <div class="footer-text">
        <a href="#header" style="color: black;" @click.prevent="gotoHead">вернуться наверх</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",

  setup() {
    function gotoHead() {
      const el = document.getElementById('header');
      el.scrollIntoView()
    }

    return {
      gotoHead
    }
  }
}
</script>

<style scoped>

.footer-container {
  margin-top: 100px;
}

.line {
  width: 100%;
  height: 1px;
  opacity: 0.5;
  background-color: black;
}

.footer-info {
  margin-top: 50px;
  text-align: center;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
}

.logo img {
  width: 55%;
  color: black;
}

.footer-text {
  font-family: "SF UI Display Regular";
  font-size: 18px;
  opacity: 0.5;
  margin: 20px 0;
}

@media only screen and (max-width: 575px) {
  .footer-container {
    margin-top: 50px;
  }
}

</style>