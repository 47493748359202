<template>
  <div class="itunes-container" id="itunes">
    <div class="banner-container" id="itunes-banner-container">
      <div class="banner-title">
        iPod и iTunes Store<span>(05)</span>
      </div>
      <div class="banner-img">
        <img src="@/assets/img/itunes-01.png" alt="iTunes">
      </div>
      <div class="banner-text">
        <p>
          В 2001 году компания Apple выпустила iPod - портативный цифровой музыкальный плеер, который навсегда изменил музыкальную индустрию.
          iPod имел мгновенный успех и помог компании Apple стать лидером на рынке потребительской электроники.
        </p>
      </div>
    </div>
    <div class="main-container">
      <div class="main-container__img" id="itunes-main-container__img">
        <img src="@/assets/img/itunes-02.png" alt="iTunes">
      </div>
      <div class="main-container__text">
        <p>
          В 2003 году компания Apple запустила iTunes Store, музыкальный интернет-магазин,
          который позволял пользователям покупать и загружать песни по цене 99 центов за каждую.
          iTunes Store стал переломным моментом, он помог преобразовать музыкальную индустрию,
          сделав для людей возможность легально приобретать музыку в Интернете простой и доступной.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import revealAnimate from "@/helpers/reveal.animation";

export default {
  name: "iPodAiTunes",

  mounted() {
    revealAnimate('#itunes-banner-container', '20%');
    revealAnimate('#itunes-main-container__img', '20%')
  }
}
</script>

<style scoped>

.itunes-container {
  margin-top: 150px;
}

.banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-title {
  position: relative;
  top: 120px;
  left: 350px;
  color: white;
  font-family: "SF UI Display Semibold";
  font-size: 70px;
}

.banner-title span {
  font-size: 1rem;
  font-style: italic;
}

.banner-img {
  width: 100%;
}

.banner-img img {
  width: 100%;
}

.banner-text {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
  width: 100%;
  text-align: left;
  position: relative;
  margin-top: -150px;
  margin-left: 50px;
  color: white;
}

.banner-text p {
  width: 40%;
}

.main-container {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.main-container__img {
  width: 40%;
  overflow: hidden;
}

.main-container__img img {
  width: 100%;
  object-fit: cover;
  transition: ease 1s;
}

.main-container__img:hover img {
  transform: scale(1.05);
}

.main-container__text {
  width: 50%;
  display: flex;
  align-items: center;
}

.main-container__text p {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
  width: 80%;
  margin: 0;
}

@media only screen and (max-width: 1200px) {
  .itunes-container {
    margin-top: 100px;
  }

  .banner-title {
    left: 150px;
  }

  .banner-text p {
    width: 70%;
  }
}

@media only screen and (max-width: 1024px) {
  .itunes-container {
    margin-top: 80px;
  }

  .banner-title {
    left: 50px;
  }

  .banner-text {
    margin-top: -165px;
  }

  .main-container__img {
    width: 48%;
  }

  .main-container__text {
    width: 48%;
  }
}

@media only screen and (max-width: 812px) {
  .itunes-container {
    margin-top: 0;
  }

  .banner-title {
    font-size: 3rem;
    top: 70px
  }

  .banner-text {
    width: auto;
    margin-right: 30px;
    margin-left: 30px;
    margin-top: -150px;
  }

  .banner-text p {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .banner-title {
    font-size: 2rem;
    top: 50px
  }

  .banner-text {
    width: auto;
    font-size: 1rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: -130px;
  }

  .banner-text p {
    width: 100%;
  }

  .main-container {
    flex-direction: column;
    margin-top: 2rem;
  }

  .main-container__img {
    order: -1;
    width: 100%;
    margin-top: 2rem;
  }

  .main-container__text {
    order: -3;
    width: 100%;
  }

  .main-container__text p {
    width: 100%;
  }
}

@media only screen and (max-width: 455px) {
  .banner-title {
    left: 0;
  }

  .banner-text {
    margin: 0;
    color: black;
  }

  .banner-text p {
    font-size: 1.25rem;
  }
}

</style>