<template>
  <div class="childhood-container" id="childhood">
    <div class="title" id="childhood-title" ref="title">Ранняя жизнь<span>(01)</span></div>

    <div class="info-container">
      <div class="childhood-img">
        <img src="@/assets/img/childhood-01.png" alt="Childhood">
      </div>
      <div class="childhood-text">
        <span>
          Стив Джобс родился 24 февраля 1955 года в Сан-Франциско, штат Калифорния.
          Его биологическими родителями были Джоанна Шибл и Абдулфаттах Джандали,
          оба аспиранты Висконсинского университета.
        </span>
      </div>
      <div class="parents-container">
        <div class="parents-img">
          <div class="parent-img">
            <img src="@/assets/img/mom.png" alt="Mommy">
            <span>Джоанна Шибл — <br>Биологическая мама Стива Джобса </span>
          </div>
          <div class="parent-img">
            <img src="@/assets/img/dad.png" alt="Daddy">
            <span>Абдулфаттах Джандали — <br>Биологический отец Стива Джобса </span>
          </div>
        </div>
      </div>
      <div class="childhood-text">
        <span>
          Однако отец Джоанны не одобрял ее отношения с Абдулфаттахом, поэтому они решили отдать сына на усыновление.
          Стива усыновили Пол и Клара Джобс, которые вырастили его в Маунтин-Вью, штат Калифорния, в самом сердце Кремниевой долины.
        </span>
      </div>
      <div class="childhood-img-small" id="test">
        <img src="@/assets/img/childhood-02.png" alt="Childhood-02">
      </div>
    </div>
  </div>
</template>

<script>
import revealAnimate from '@/helpers/reveal.animation';

export default {
  name: "Childhood",

  mounted() {
    revealAnimate('.childhood-img', '20%');
    revealAnimate('.parent-img', '20%');
    revealAnimate('.childhood-img-small', '20%');
  }
}
</script>

<style scoped>

.childhood-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.title {
  width: 100%;
  font-family: "SF UI Display Semibold";
  font-size: 70px;
  clip-path: polygon(0 10%, 100% 10%, 100% 100%, 0% 100%);
}

.title span {
  font-size: 1rem;
  font-style: italic;
}

.info-container {
  width: 70%;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.childhood-img {
  margin-top: 50px;
  width: 100%;
  overflow: hidden;
}

.childhood-img img {
  width: 100%;
  transition: ease 1s;
  object-fit: cover;
}

.childhood-img:hover img {
  transform: scale(1.05);
}

.childhood-text {
  display: flex;
  align-items: flex-start;
}

.childhood-text span {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
  text-align: left;
  width: 50%;
  margin: 2rem 0;
}

.parents-container {
  width: 100%;
}

.parents-img {
  display: flex;
}

.parent-img {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 8rem;
  overflow: hidden;
}

.parent-img img {
  width: 100%;
  transition: ease 1s;
  object-fit: cover;
}

.parent-img:hover img {
  transform: scale(1.05);
}

.parents-img span {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
  margin-top: 1rem;
}

.childhood-img-small {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.childhood-img-small img {
  width: 55%;
  object-fit: cover;
  transition: ease 1s;
}

.childhood-img-small:hover img {
  transform: scale(1.05);
}

@media only screen and (max-width: 1200px) {
  .childhood-container {
    margin-top: 50px;
  }

  .childhood-text span {
    width: 70%;
  }

  .parent-img {
    margin-right: 2rem;
    width: 100%;
  }

  .childhood-img-small img {
    width: 100%;
  }
}

@media only screen and (max-width: 812px) {
  .title {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 575px) {
  .title {
    font-size: 2rem;
  }

  .info-container {
    width: 100%;
  }

  .childhood-img {
    margin-top: 2rem;
  }

  .childhood-text span {
    width: 100%;
  }

  .parents-img span {
    font-size: 1rem;
  }

  .parent-img {
    margin: 0 1rem;
  }
}

</style>