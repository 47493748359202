<template>
  <div class="context" id="context-menu">
    <div class="img-container">
      <img src="@/assets/img/context-header.png" alt="context">
    </div>
    <div class="context-text">
      <div class="context-title">
        Стив Джобс - квинтэссенция американского предпринимателя, <br>
        чья история - это смесь триумфов, неудач и извлеченных уроков.
      </div>
      <div class="context-list">
        <a :href="link.url" class="list-item block" v-for="link in links" @click.prevent="goToOption(link.url)">
          {{ link.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {gsap} from "gsap";

export default {
  name: "ContextList",

  data() {
    return {
      links: [
        {name: 'Ранняя жизнь', url: '#childhood'},
        {name: 'Образование', url: '#education'},
        {name: 'Основание компании Apple', url: '#apple'},
        {name: 'Уход из Apple', url: '#apple'},
        {name: 'Возвращение в Apple', url: '#return-apple'},
        {name: 'iPod и iTunes Store', url: '#itunes'},
        {name: 'iPhone и iPad', url: '#iphone'},
        {name: 'Последние годы', url: '#end'},
        {name: 'Наследие', url: '#inheritance'},
      ]
    }
  },

  methods: {
    goToOption(el) {
      const element = document.querySelector(el);
      element.scrollIntoView();
    }
  },

  setup() {
    const img = document.querySelector('.img-container img');

  }
}
</script>

<style scoped>

.context {
  margin-top: 100px;
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.img-container {
  width: 45%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
  height: 80%;
  object-fit: cover;
  transition: ease 1s;
}

.img-container:hover img {
  transform: scale(1.05);
}

.context-text {
  width: 45%;
}

.context-title {
  font-size: 1.5rem;
  font-family: "SF UI Display Regular";
}

.context-list {
  margin-top: 60px;
}

.block {
  display: block;
}

.list-item {
  font-family: "SF UI Display Medium";
  text-decoration: none;
  font-size: 2rem;
  opacity: 0.5;
  color: black;
  margin: 1rem 0;
  cursor: pointer;
  transition: 0.3s;
}

.list-item:hover {
  opacity: 1;
}

@media only screen and (max-width: 1200px) {
  .list-item {
    font-size: 1.5rem;
    margin: 10px 0;
  }

  .context-list {
    margin-top: 20px;
  }

  .img-container {
    width: 48%;
  }

  .context-text {
    width: 48%;
  }
}

@media only screen and (max-width: 812px) {
  .context-title {
    font-size: 1.5rem;
  }

  .list-item {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .context {
    margin-top: 50px;
  }

  .context-title {
    font-size: 1rem;
  }

  .list-item {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 455px) {
  .context {
    flex-direction: column;
  }

  .img-container {
    width: 100%;
  }

  .context-text {
    width: 100%;
    text-align: center;
  }

  .context-title {
    text-align: left;
  }
}

</style>