<template>
  <div class="ipad-container" id="iphone">
    <div class="ipad-header">
      <div class="header-title" id="iphone-title">iPhone и iPad Store<span>(06)</span></div>
      <div class="header-subtitle">
        <p>
          iPhone и iPad:
          В 2007 году компания Apple выпустила iPhone - революционный смартфон,
          который изменил представление людей о взаимодействии с технологиями.
          iPhone имел огромный успех и помог компании Apple стать лидером на рынке мобильных телефонов.
          <br> <br>
          В 2010 году компания Apple выпустила iPad - планшетный компьютер,
          который изменил представление людей о потреблении медиа и взаимодействии с технологиями.
          iPad имел еще один огромный успех и помог компании Apple стать лидером на рынке планшетных компьютеров.
        </p>
      </div>
    </div>
    <div class="ipad-images">
      <div class="img-container">
        <div class="img-01" id="iphone-img">
          <img src="@/assets/img/iphone-02.png" alt="iPad">
        </div>
        <div class="img-02" id="iphone-img">
          <img src="@/assets/img/iphone-01.png" alt="iPad">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import revealAnimate from "@/helpers/reveal.animation";

export default {
  name: "iPhoneAiPad",

  mounted() {
    revealAnimate('#iphone-img', '20%');
  }
}

</script>

<style scoped>

.ipad-container {
  margin-top: 150px;
}

.header-title {
  font-family: "SF UI Display Semibold";
  font-size: 70px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.header-title span {
  font-size: 1rem;
  font-style: italic;
}

.header-subtitle {
  margin-left: 50px;
  margin-top: 80px;
  width: 50%;
}

.header-subtitle p {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
}

.ipad-images {
  display: flex;
  justify-content: flex-end;
}

.img-container {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.img-01 {
  width: 40%;
  overflow: hidden;
}

.img-01 img {
  width: 100%;
  object-fit: cover;
  transition: ease 1s;
}

.img-01:hover img {
  transform: scale(1.05);
}

.img-02 {
  width: 55%;
  overflow: hidden;
}

.img-02 img {
  width: 100%;
  object-fit: cover;
  transition: ease 1s;
}

.img-02:hover img {
  transform: scale(1.05);
}

@media only screen and (max-width: 1024px) {
  .ipad-container {
    margin-top: 100px;
  }

  .header-subtitle {
    margin-top: 50px;
    width: 80%;
  }

  .img-container {
    width: 60%;
  }
}

@media only screen and (max-width: 812px) {
  .header-title {
    font-size: 3rem;
  }

  .img-container {
    width: 70%;
  }
}

@media only screen and (max-width: 575px) {
  .ipad-container {
    margin-top: 50px;
  }

  .header-title {
    font-size: 2rem;
  }

  .header-subtitle {
    margin-top: 2rem;
    margin-left: 0;
    width: 100%;
  }

  .img-container {
    width: 80%;
  }
}

@media only screen and (max-width: 455px) {
  .img-container {
    width: 100%;
  }
}

</style>