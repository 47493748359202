<template>
  <div class="education-container" id="education">
    <div class="title" id="education-title" ref="edu-title">Образование<span>(02)</span></div>
    <div class="info-container">
      <div class="subtitle-tags">#Хоумстед #Рид</div>
      <div class="school-img">
        <img src="@/assets/img/school-01.png" alt="school">
      </div>
      <div class="school-info">
        <div class="school-info__chapter">
          <div class="chapter-year">1963</div>
          <div class="chapter-text">Стив Джобс посещал среднюю школу Хоумстед в Купертино, Калифорния.
            Он был способным учеником, но часто конфликтовал со своими учителями и школьной администрацией.
            Он был бунтарем, который отказывался подчиняться правилам и нормам системы образования.
          </div>
        </div>

        <div class="school-info__chapter">
          <div class="chapter-year">1972</div>
          <div class="chapter-text">После окончания школы в 1972 году Стив Джобс поступил в колледж Рид в Портленде, штат Орегон.
            Однако он бросил учебу, проучившись всего один семестр. Он продолжал посещать занятия в Рид-колледже,
            в том числе уроки каллиграфии, которые впоследствии повлияли на его дизайнерское мышление.
          </div>
        </div>
      </div>
      <div class="college-img">
        <img src="@/assets/img/school-02.png" alt="College">
      </div>
    </div>
  </div>
</template>

<script>
import revealAnimate from "@/helpers/reveal.animation";

export default {
  name: "Education",

  mounted() {
    revealAnimate('.school-img', '20%');
    revealAnimate('.college-img', '20%');
  }
}
</script>

<style scoped>

.education-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 150px;
}

.title {
  width: 100%;
  text-align: right;
  font-family: "SF UI Display Semibold";
  font-size: 70px;
  clip-path: polygon(0 10%, 100% 10%, 100% 100%, 0% 100%);
}

.title span {
  font-size: 1rem;
  font-style: italic;
}

.info-container {
  margin-top: 50px;
  width: 70%;
}

.subtitle-tags {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
}

.school-img {
  overflow: hidden;
}

.school-img img {
  width: 100%;
  margin: 50px 0;
  object-fit: cover;
  transition: ease 1s;
}

.school-img:hover img {
  transform: scale(1.05);
}

.school-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.school-info__chapter {
  width: 65%;
  margin-bottom: 100px;
}

.chapter-year {
  font-family: "SF UI Display Regular";
  font-size: 2rem;
}

.chapter-text {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
}

.college-img {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.college-img img {
  width: 70%;
  object-fit: cover;
  transition: ease 1s;
}

.college-img:hover img {
  transform: scale(1.05);
}

@media only screen and (max-width: 1200px) {
  .college-img img {
    width: 80%;
  }
}

@media only screen and (max-width: 1024px) {
  .education-container {
    margin-top: 100px;
  }

  .info-container {
    margin-top: 30px;
  }

  .school-img img {
    margin-top: 30px;
  }

  .school-info__chapter {
    width: 80%;
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 812px) {
  .title {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 575px) {
  .title {
    font-size: 2rem;
  }

  .education-container {
    margin-top: 50px;
  }

  .subtitle-tags {
    font-size: 1rem;
  }

  .info-container {
    width: 100%;
  }

  .school-img img {
    margin: 30px 0;
  }

  .school-info__chapter {
    width: 100%;
    margin-bottom: 50px;
  }
}

</style>