<template>
  <div class="return-container" id="return-apple">
    <div class="return-header">
      <div class="header-text">
        <div class="header-title" id="return-title">
          <p>Возвращение в Apple<span>(04)</span></p>
        </div>
      </div>
      <div class="header-img" id="return-header-img">
        <img src="@/assets/img/return-01.png" alt="Return Apple">
      </div>
    </div>

    <div class="return-main">
      <div class="main-right">
        <div class="right-img" id="return-left-img">
          <img src="@/assets/img/return-02.png" alt="Return">
        </div>
      </div>
      <div class="main-left">
        <div class="left-text">
          <div class="left-text__title">1980-1984</div>
          <div class="left-text__desc">
            В конце 1970-х - начале 1980-х годов компания Apple стала доминирующим игроком в индустрии персональных компьютеров.
            Apple II был одним из самых популярных персональных компьютеров того времени, и благодаря ему компания Apple стала известна всем.
            <br> <br>
            В 1984 году Apple выпустила Macintosh, революционный персональный компьютер с графическим интерфейсом пользователя и мышью.
            Macintosh стал переломным моментом в индустрии и помог компании Apple завоевать репутацию производителя инноваций и превосходного дизайна.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import revealAnimate from "@/helpers/reveal.animation";

export default {
  name: "ReturnToApple",

  mounted() {
    revealAnimate('#return-header-img', '20%');
    revealAnimate('#return-left-img', '20%')
  }
}
</script>

<style scoped>

.return-container {
  margin-top: 200px;
}

.return-header {
  display: flex;
  justify-content: space-between;
}

.header-text {
  position: relative;
  left: 250px;
  width: 50%;
}

.header-title p {
  font-family: "SF UI Display Semibold";
  font-size: 70px;
  text-align: right;
  margin-top: 10px;
}

.header-title span {
  font-size: 1rem;
  font-style: italic;
}

.header-img {
  width: 48%;
  overflow: hidden;
  z-index: -1;
}

.header-img img {
  width: 100%;
  object-fit: cover;
}

.return-main {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.main-right {
  width: 48%;
}

.right-img {
  overflow: hidden;
}

.right-img img {
  width: 100%;
  object-fit: cover;
  transition: ease 1s;
}

.right-img:hover img {
  transform: scale(1.05);
}

.main-left {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left-text__title {
  font-size: 2.5rem;
  font-family: "SF UI Display Semibold";
  font-style: italic;
  margin-bottom: 2rem;
}

.left-text__desc {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
}

@media only screen and (max-width: 1200px) {
  .return-container {
    margin-top: 150px;
  }
  .header-text {
    left: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .header-title p {
    text-align: left;
  }
}

@media only screen and (max-width: 812px) {
  .return-container {
    margin-top: 100px;
  }

  .return-header {
    flex-direction: column;
  }

  .header-text {
    width: 100%;
  }

  .header-title p {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  .header-img {
    width: 100%;
  }

  .return-main {
    flex-direction: column;
  }

  .main-right {
    order: 3;
    width: 100%;
    margin-top: 50px;
  }

  .main-left {
    order: -1;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .return-container {
    margin-top: 50px;
  }

  .header-title p {
    font-size: 2rem;
  }

  .return-main {
    margin-top: 2rem;
  }

  .left-text__title {
    font-size: 2rem;
  }

  .main-right {
    margin-top: 2rem;
  }
}

</style>