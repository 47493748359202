import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function revealAnimate(el, yValue) {
// Register ScrollTrigger with GSAP
    gsap.registerPlugin(ScrollTrigger);

// Define the target element
    const element = document.querySelectorAll(el);

// Define the animation
    const revealAnimation = gsap.timeline({
        defaults: { duration: 1, ease: "power2.out" },
        scrollTrigger: {
            trigger: element,
            start: "top 90%", // Start the animation when the element is 90% in view
        },
    });

// Add the animation steps
    revealAnimation.from(element, { y: yValue, opacity: 0 });
}

// Export the animation for reuse
export default revealAnimate;