<template>
  <div class="header-text" id="header">
    <div class="app-title" ref="title">Think different</div>
    <div class="app-subtitle">История Стива Джобса и Apple.</div>
  </div>
  <div class="scroll-area">
    <a href="#context-menu" style="text-decoration: none; color: black" @click.prevent="scrollDown">
      <span>
        <div>SCROLL</div>
        <div>/</div>
        <div>DOWN</div>
      </span>
    </a>
  </div>
  <div class="main-info">
    <p>
      <span>●</span>Стив Джобс, соучредитель компании Apple, был загадочной и противоречивой фигурой в технологической индустрии. Он был провидцем,
      бунтарем и творческим гением. Он известен своими лидерскими качествами, дизайнерским чутьем и сверхъестественной способностью предсказывать
      будущее технологий.
    </p>
  </div>
</template>

<script>
import SplitType from "split-type";
import {gsap} from "gsap";

export default {
  name: "Header",
  emits: ['window-resized'],

  data() {
    return {
      windowWidth: window.innerWidth / 6.8 + 'px',
    }
  },

  mounted() {
    const title = new SplitType(this.$refs.title)

    gsap.to('.char', {
      y: 0,
      stagger: 0.05,
      delay: 0.04,
      duration: 0
    })

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth / 6.8 + 'px'
      this.$emit('window-resized', this.windowWidth)
    })
  },

  methods: {
    scrollDown() {
      const el = document.getElementById('context-menu');
      el.scrollIntoView();
    }
  }
}
</script>

<style scoped>

.app-title {
  font-family: "SF UI Display Medium", sans-serif;
  font-size: v-bind(windowWidth);
  text-align: center;
  width: 100%;
  letter-spacing: -2px;
  margin: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.app-subtitle {
  font-family: "SF UI Display Medium";
  font-size: 2.5rem;
  position: absolute;
  right: 50px;
}

.scroll-area {
  cursor: pointer;
  font-family: "SF UI Display Medium";
  font-size: 1rem;
  margin-top: 190px;
  text-align: center;
}

.main-info {
  margin-top: 100px;
  width: 40%;
  font-family: "SF UI Display Regular";
  font-size: 1.75rem;
}

.main-info span {
  margin-right: 20px;
}

@media only screen and (max-width: 1200px) {
  .app-subtitle {
    font-size: 2rem;
  }

  .scroll-area {
    margin-top: 140px;
  }

  .main-info {
    width: 70%;
  }
}

@media only screen and (max-width: 1024px) {
  .main-info {
    width: 80%;
  }
}

@media only screen and (max-width: 812px) {
  .main-info {
    width: 80%;
  }

  .app-subtitle {
    text-align: center;
    position: static;
  }

  .main-info {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .scroll-area {
    margin-top: 50px;
  }

  .main-info {
    margin-top: 50px;
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 455px) {
  .app-subtitle {
    font-size: 1.25rem;
  }
}

</style>