<template>
  <div class="company-container" id="apple">
    <div class="company-header">
      <div class="header-img" id="apple-header-img">
        <img src="@/assets/img/company-01.png" alt="Company">
      </div>
      <div class="header-text">
        <div class="header-title" id="apple-title">
          <p>
            Основание компании
          </p>
          <p>
            Apple<span>(03)</span>
          </p>
        </div>
        <div class="header-subtitle">
          <div class="header-subtitle__year">1970-1980</div>
          <div class="header-subtitle__city">California, <br>San francisco</div>
        </div>
      </div>
    </div>
    <div class="company-main">
      <div class="main-left">
        <div class="main-left__img" id="apple-main-left__img">
          <img src="@/assets/img/company-02.png" alt="Steve">
        </div>
        <div class="main-left__text">
          <p>
            В 1974 году Стив Джобс вернулся в Калифорнию и начал посещать собрания "Домашнего компьютерного клуба" - группы любителей,
            которые интересовались созданием и программированием собственных компьютеров.
            Он познакомился со Стивом Возняком, товарищем по увлечению компьютерами, и они подружились.
          </p>
        </div>
        <div class="main-left__img" id="apple-main-left__img-02" style="margin-top: 100px">
          <img src="@/assets/img/company-04.png" alt="Steve-03">
        </div>
      </div>
      <div class="main-right">
        <div class="main-right__text">
          <p>В 1974 году Стив Джобс вернулся в Калифорнию и начал посещать собрания "Домашнего компьютерного клуба"</p>
        </div>
        <div class="main-right__img" id="apple-main-right__img">
          <img src="@/assets/img/company-03.png" alt="Steve-02">
        </div>
        <div class="main-right__text__long">
          <p>В 1976 <br>
            году Стив Джобс и Стив Возняк основали компанию Apple Computer в гараже родителей Джобса. Они начали со сборки и продажи Apple I, комплекта одноплатного компьютера. Apple I имел огромный успех и проложил путь к созданию Apple II, более совершенного персонального компьютера, который был выпущен в 1977 году.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import revealAnimate from "@/helpers/reveal.animation";

export default {
  name: "AppleCompany",

  mounted() {
    revealAnimate('#apple-header-img', '20%');
    revealAnimate('#apple-main-left__img', '20%');
    revealAnimate('#apple-main-right__img', '20%');
    revealAnimate('#apple-main-left__img-02', '20%');
  }
}
</script>

<style scoped>

.company-container {
  margin-top: 180px;
}

.company-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
}

.header-text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header-title {
  width: 100%;
  position: relative;
  right: 50%;
  top: 40px;
}

.header-title p {
  font-family: "SF UI Display Semibold";
  font-size: 70px;
  text-align: right;
  line-height: 60px;
  margin: 0;
}

.header-title span {
  font-size: 1rem;
  font-style: italic;
}

.header-img {
  width: 48%;
  overflow: hidden;
}

.header-img img {
  width: 100%;
  object-fit: cover;
  transition: ease 1s;
}

.header-img:hover img {
  transform: scale(1.05);
}

.header-subtitle {
  width: 100%;
}

.header-subtitle__year {
  font-family: "SF UI Display Regular";
  font-size: 2rem;
}

.header-subtitle__city {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
  opacity: 0.5;
  margin-top: 10px;
}

.company-main {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.main-left__img {
  width: 100%;
  overflow: hidden;
}

.main-left__img img {
  width: 60%;
  object-fit: cover;
  transition: ease 1s;
}

.main-left__img:hover img {
  transform: scale(1.05);
}

.main-left__text p {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
}

.main-left__text {
  width: 85%;
  margin-top: 30px;
}

.main-right__img {
  overflow: hidden;
}

.main-right__img img {
  width: 100%;
  object-fit: cover;
  transition: ease 1s;
}

.main-right__img:hover img {
  transform: scale(1.05);
}

.main-right__text p {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
  text-align: right;
}

.main-right__text__long {
  font-family: "SF UI Display Regular";
  font-size: 1.25rem;
  text-align: left;
  margin-top: 50px;
}

@media only screen and (max-width: 1200px) {
  .header-title {
    right: 10%;
    top: 0;
  }

  .main-left__img img {
    width: 70%;
  }
}

@media only screen and (max-width: 1024px) {
  .header-title {
    right: 0;
  }
}

@media only screen and (max-width: 812px) {
  .company-container {
    margin-top: 100px;
  }

  .company-header {
    flex-direction: column;
    align-items: center;
  }

  .header-text {
    order: 1;
    width: 100%;
    text-align: center;
  }

  .header-title p {
    font-size: 3rem;
    text-align: center;
  }

  .header-img {
    order: 10;
    width: 100%;
    margin-top: 1rem;
  }

  .main-left__img img {
    width: 90%;
  }

  .main-left__text {
    width: 90%;
  }
}

@media only screen and (max-width: 575px) {
  .header-title p {
    font-size: 2rem;
    margin: 0;
  }
}

@media only screen and (max-width: 455px) {
  .company-container {
    margin-top: 50px;
  }

  .company-main {
    flex-direction: column;
  }

  .main-left__img {
    margin-top: 1.5rem !important;
  }

  .main-left__img img {
    width: 100%;
  }

  .main-right__text p {
    text-align: left;
  }
}

</style>